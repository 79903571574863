import $ from 'jquery';

// import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};


/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from './scripts/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as navToggle from './scripts/nav-toggle';
app.navToggle = navToggle.init;

import * as toggleNavLevel from './scripts/toggle-navlevel';
app.modules.toggleNavLevel = toggleNavLevel.initInScope;

import * as touchNav from '@elements/touch-nav';
app.modules.touchNav = touchNav.initInScope;

import * as slider from '@elements/slider';

import * as heroSlider from './scripts/hero-slider';
app.modules.heroSlider = heroSlider.initInScope;

app.modules.teaserSliderGutter = slider.createInitInScope('.js-teaser-slider-gutter', {
    slidesToShow: matchMedia('(max-width: 767px)').matches ? 1.2 : 3,
    slidesToScroll: 1,
    initialSlide: 0
});

app.modules.teaserSlider = slider.createInitInScope('.js-teaser-slider', {
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    centerMode: true,
    centerPadding: "0px",
    speed: 450,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                initialSlide: 0
            }
        }
    ]
});

app.modules.imgSlider = slider.createInitInScope('.js-img-slider', {
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
        {
            breakpoint: 767,
            settings: {
                initialSlide: 0
            }
        }
    ]
});


app.modules.accoTeaserSlider = slider.createInitInScope('.js-acco-teaser-slider', {
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
                initialSlide: 0
            }
        }
    ]
});

if (matchMedia('(max-width: 767px)').matches) {
    app.modules.weatherSlider = slider.createInitInScope('.js-weather-slider', {
        slidesToShow: 2.5,
        slidesToScroll: 1,
        infinite: false,
        swipeToSlide: true
    });
}


// import * as smoothScroll from '@elements/smooth-scroll';
// smoothScroll.init({offset: 200}, {link: '.js-smoothscroll__link', dataOffset: 'smoothscroll-offset'});
// smoothScroll.init();
// smoothScroll.initInScope();

import * as smoothScroll from './scripts/smooth-scroll';
app.modules.smoothScroll = smoothScroll.initInScope;

import * as scrollSpy from './scripts/scroll-spy.js';
app.modules.scrollSpy = scrollSpy.initInScope;

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;


// workaround for parsley bug. es funktioniert, warum genau weiß man nicht :D
$('.js-tab-with-parsley').on('shown.bs.tab', function (event) {
    let id = $(event.target).attr('href');
    let form =  $(id).find('form');
    form.parsley().destroy();
})

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as datepicker from './scripts/datepicker';
app.modules.datepicker = datepicker.initInScope;

import * as datepickerRange from '@elements/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

import * as datepickerBox from './scripts/datepicker-box';
app.modules.datepickerBox = datepickerBox.initInScope;

import * as asyncYoutube from './scripts/async-youtube';
app.modules.asyncYoutube = asyncYoutube.initInScope;

import * as toggleNotification from './scripts/toggle-notification';
app.modules.toggleNotification = toggleNotification.initInScope;

import * as customTracking from './scripts/custom-tracking';
app.modules.customTracking = customTracking.initInScope;

import * as tracking from './scripts/tracking';
let options = {
    debug: false
}; // optional options

tracking.initGtmEventTracking(options);
_config.tracking = tracking;

import * as ajaxForm from './scripts/ajax-form';
app.modules.ajaxForm = ajaxForm.createInitInScope({
    addUrlParams: true,
    submitOnChange: true,
    allowScriptTags: true
});

import * as ajaxFormScroll from './scripts/ajax-form-scroll';
app.modules.ajaxFormScroll = ajaxFormScroll.initInScope;

import * as toggleOffscreen from './scripts/toggle-offscreen';
app.modules.toggleOffscreen = toggleOffscreen.initInScope;

import * as transformIn from './scripts/transform-in';
app.modules.transformIn = transformIn.initInScope;

import * as chartJs from './scripts/chartjs';
app.modules.chartJs = chartJs.initInScope;

import * as fetchTabs from './scripts/fetch-tabs';
app.modules.fetchTabs = fetchTabs.initInScope;

import * as popover from './scripts/popover';
app.modules.popover = popover.initInScope;

import * as fileInput from '@elements/file-input';
app.modules.fileInput = fileInput.initInScope;


//shop components
import * as cart from './scripts/cart';
app.cart = cart.init;
app.modules.cart = cart.initInScope;

import * as wizard from './scripts/wizard';
app.modules.wizard = wizard.initInScope;

import * as updatePrice from './scripts/update-price';
app.modules.updatePrice = updatePrice.initInScope;

import * as availabilityDatepicker from './scripts/availability-datepicker';
app.modules.availabilityDatepicker = availabilityDatepicker.initInScope;


import * as numberSpinner from '@elements/number-spinner';
app.modules.numberSpinner = numberSpinner.initInScope;

import * as tooltip from './scripts/tooltip';
app.modules.tooltip = tooltip.initInScope;

import * as numberSpinnerGroup from './scripts/numberSpinnerGroup';
app.modules.numberSpinnerGroup = numberSpinnerGroup.initInScope;

import * as dependantNumberSpinner from './scripts/dependant-number-spinners';
app.modules.dependantNumberSpinner = dependantNumberSpinner.initInScope;

app.modules.inlineDatepicker = datepicker.createInitInScope({
     base: '.js-datepicker-inline',
     input: '.js-datepicker__input',
     altField: '.js-datepicker__alt-field'
 }, {
     /*options*/
     numberOfMonths: 1,
     navigationAsDateFormat: true,
     prevText: 'MM',
     nextText: 'MM'
});

import * as affix from './scripts/affix';
if (matchMedia('(min-width: 768px)').matches) {
    app.modules.affix = affix.initInScope;
}

import * as dateInput from '@elements/date-input';
app.modules.dateInput = dateInput.createInitInScope({
    placeholderDate: null
});

import * as dataBox from './scripts/data-box';
app.modules.dataBox = dataBox.initInScope;

import * as collapseCheckbox from './scripts/collapse-checkbox';
app.modules.collapseCheckbox = collapseCheckbox.initInScope;

import * as address from './scripts/address';
app.modules.address = address.initInScope;

import * as inputMask from './scripts/input-mask';
app.modules.inputMask = inputMask.initInScope;

import * as qrSanner from './scripts/qr-sanner';
app.modules.qrSanner = qrSanner.initInScope;

import * as updateSelection from './scripts/update-selection';
app.modules.updateSelection = updateSelection.initInScope;

import * as flexview from './scripts/flexview';
app.flexview = flexview.init;

import * as infoDropdown from './scripts/info-dropdown';
app.modules.infoDropdown = infoDropdown.initInScope;

import * as scrollTabs from './scripts/scroll-tabs';
app.modules.scrollTabs = scrollTabs.initInScope;

import * as copyValues from './scripts/copy-values';
app.modules.copyValues = copyValues.initInScope;

import * as readmore from './scripts/readmore';
app.modules.readmore = readmore.initInScope;

import {translate} from '@elements/translations';

import * as quickfinder from '@elements/quickfinder';
app.modules.quickfinder = quickfinder.createInitInScope('.js-quickfinder', {},
    renderChildAgeInput
);

function renderChildAgeInput({index, value, onChange}) {
    let markup = `<div class="col-3">` +
        `<div class="select form-group">` +
        `<div class="select__input-wrapper position-relative">` +
        `<label class="form-control-label floating-label" for="childAge${index}"> ${translate('qf.childAge')} ${index}</label>` +
        `<select name="ca0[]" id="childAge${index}" name="childAge${index}" class="form-control js-floating-label" required>` +
        `<option class="d-none" value="" ${ value ? '': 'selected'} ></option>`;

    for (let i = 0; i < 18; i++) {
        markup += `<option value="${i}" ${i == value ? 'selected': ''}>${i} ${translate('qf.Jahre')}</option>`;
    }

    markup += `</select>` +
        `<div class="form-errors invalid-feedback"></div>` +
        `</div></div></div>`;

    let $input = $(markup);

    $input.on('change', function (e) {
        onChange(e.target.value);
    });

    return $input;
}

import * as typeahead from './scripts/typeahead';
app.modules.typeahead = typeahead.initInScope;

import * as selectCollapse from './scripts/select-collapse';
app.modules.selectCollapse = selectCollapse.initInScope;

import * as targetingForm from './scripts/targeting-form';
app.modules.targetingForm = targetingForm.initInScope;

import * as allowTargeting from './scripts/allow-targeting';
app.modules.allowTargeting = allowTargeting.initInScope;

import * as topCard from './scripts/top-card';
app.modules.topCard = topCard.initInScope;

import * as takePhoto from './scripts/take-photo';
app.modules.takePhoto = takePhoto.initInScope;

import * as reloadForm from './scripts/reload-form';
app.modules.reloadForm = reloadForm.initInScope;

import * as ajaxModal from './scripts/ajax-modal';
app.modules.ajaxModal = ajaxModal.initInScope;

import * as skipLinks from './scripts/skip-links';
app.modules.skipLinks = skipLinks.initInScope;

import * as loginTabs from './scripts/login-tabs';
app.loginTabs = loginTabs.init;

import * as consentOverlay from './scripts/cookie-consent-overlay';
app.modules.consentOverlay = consentOverlay.initInScope;

import * as poiMap from './scripts/poi-map';
app.poiMap = poiMap.init;

import * as ticketModal from './scripts/ticket-modal';
app.modules.ticketModal = ticketModal.initInScope;

import * as affixNav from './scripts/affix-nav';
affixNav.init();

import "@lottiefiles/lottie-player";

import * as selectRedirect from './scripts/select-redirect';
selectRedirect.init();

import * as recaptcha from './scripts/recaptcha';
import {initGtmEventTracking, initGtmFormSentEventTracking, initGtmSentEventTracking} from "./scripts/tracking";
app.modules.recaptcha = recaptcha.initInScope;

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);
